.services > h2{
    width: 100%;
    text-align: center;
    padding: 25px 0;
}

.servicesWrapper{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
    padding: 25px 0;
}

.servicesItem{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    box-shadow: inset 0 0 15px #d3d3d3, 3px 3px 5px 1px #d3d3d3;
    transition: .3s;
    cursor: pointer;
}

.servicesItemInfo{
    display: none;
    color: white;
    font-size: 1.1em;
    text-align: center;
    padding: 15px;
}

.servicesItem:hover{
    background-color: var(--main-color);
    box-shadow: inset 0 0 15px var(--main-color-hover), 3px 3px 5px 1px #d3d3d3;
}

.servicesItem:hover h3{
    display: none;
}

.servicesItem:hover .servicesItemInfo{
    display: block;
}
