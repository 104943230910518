.mapWrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mapWrapper > h2{
    text-align: center;
}

.map{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    background-color: white;
    color: var(--main-color);
}

.mapStats{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    margin: 25px 0;
}

.mapStatsItem{
    padding: 15px;
    background-color: white;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 15px #e7e7e7;
    border-radius: 10px;
    cursor: pointer;
    transition: .3s;
}

.mapStatsItem > b{
    font-size: 2.5em;
}

.mapStatsItem:hover{
    box-shadow: 0 0 25px #d5d5d5;
}
