.newsWrapper{
    width: 100%;
    min-height: 100vh;
    max-width: 1280px;
    margin: 0 auto;
    padding: 70px 0;
}

.newsWrapper > h2{
    width: 100%;
    padding: 50px 0;
    text-align: center;
}
