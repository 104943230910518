.newsFilter {
    width: 75%;
    margin: 0 auto;
    border: solid 1px #a9a9a9;
    border-radius: 5px;
    padding: 25px;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.newsFilterSearch{
    flex: 1;
}

.newsFilter input {
    padding: 12px;
    font-size: 1.05em;
}
