.error{
    height: 100vh;
    width: 100%;
    background-color: #2b394a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    position: relative;
}

.error > h2{
    color: #35a9e0;
}

.errorIcon{
    position: absolute;
    top: -16px;
    right: -64px;
    color: white;
}
