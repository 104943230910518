.primary-button{
    background-color: var(--main-color);
    color: white;
    outline: none;
    padding: 12px;
    font-size: 1.05em;
    box-sizing: border-box;
    cursor: pointer;
    border: 0;
    box-shadow: 3px 3px var(--main-color-darker);
    border-radius: 2px;
    transition: .3s;
}

.primary-button:hover{
    box-shadow: 0px 0px var(--main-color-darker);
}
