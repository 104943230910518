#footer {
    background-color: var(--main-dark-color);
}

.footerWrapper {
    padding-top: 20px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template: repeat(2, auto) / repeat(3, 1fr);
    justify-content: center;
    grid-gap: 10px 0;
    color: white;
}

.footerButtons {
    display: flex;
    justify-content: center;
}

.footerContacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.footerContacts li {
    padding: 3px;
    text-align: center;
}

.footerContacts a {
    color: white;
}

.footerNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.footerNavigation li {
    padding: 3px;
    text-align: center;
}

.footerNavigation a {
    color: white;
}

.footerCopyright {
    width: 100%;
    grid-column: span 3;
    grid-row: 2;
    text-align: center;
    padding: 10px 0;
    border-top: dotted 1px white;
}

@media (max-width: 720px) {
    .footerWrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}
