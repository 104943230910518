.comingSoon {
    min-height: 100vh;
    background-color: var(--main-color);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
}

.comingSoonWrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comingSoonWrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(53, 169, 224, 0.52);
}

.comingSoonWrapper h1{
    position: relative;
    font-size: 5em;
    font-weight: bold;
    color: white;
    text-align: center;
}

.comingSoonDescription{
    position: relative;
    font-weight: bold;
    color: white;
    text-align: center;
}
