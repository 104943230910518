@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style-type: none;
}

html{
    height: 100%;
}

body{
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
    height: 100%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--main-color) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 10px;
    border: 3px solid #ffffff;
}
