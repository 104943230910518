.aboutWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 50px 0;
}

.aboutInfo{
    font-size: 1.2em;
    text-align: center;
}

.aboutInfo hr{
    margin-top: 25px;
    background-color: var(--main-color);
    border: solid 1px var(--main-color);
}
